import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import schemaSettings from '../../../shared/schemas/carpool-schema'
import { createProviders } from '../providers'
import { redirectUnauthorized, createCrudState } from '../factories'
import { showFeedbackFromError } from '../feedback/factories'

const { carpoolSchema: schema } = schemaSettings
const service = apiClient.service('carpools')
const carpoolLocationHistoryService = apiClient.service('carpools/history')

export default ({ name, app }) => {
  app.on('initialized', () => {
    const updateEntities = app.getSequence('carpools.updateEntities')
    const setList = app.getSequence('carpools.updateList')
    service.on('patched', (carpool) => {
      updateEntities({ entities: { carpools: [carpool] } })
      const list = app.getState('carpools.list')
      const route = app.getState('route')
      if (route.key !== 'toCarpools') {
        return
      }
      const currentStatus = route.params.filter || 'building'
      let ids = list.ids
      if (carpool.status === currentStatus) {
        ids = Array.from(new Set([...list.ids, carpool._id]))
      } else if (list.ids.includes(carpool._id)) {
        ids = list.ids.filter((id) => id !== carpool._id)
      }
      setList({ result: { ids } })
    })
    carpoolLocationHistoryService.on('patched', (carpool) => updateEntities({ entities: { carpools: [carpool] } }))
  })
  return {
    state: {
      ...createCrudState({ entityType: 'carpools', storePrefix: name }),
    },
    sequences,
    providers: createProviders({ name, service, schema }),
    catch: [
      redirectUnauthorized(),
      [
        Error,
        showFeedbackFromError({ title: 'We encountered an error' }),
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
