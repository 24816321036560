const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const mobileUserTypes = require('../mobile-user-types')
const { types, frequencies } = require('../carpool-rewards')
const { dailySchedules } = require('./fields/daily-schedules')

const shape = labelify({
  name: yup.string().label('Title').default(''),
  type: yup.string().oneOf(types).default('carpool'),
  description: yup.string().default(''),
  pointsForOrganizer: yup.number().min(0).default(0),
  pointsForMember: yup.number().min(0).default(0),
  pointsForOrganizerPerMember: yup.number().min(0).default(0),
  maximumCompletionFrequency: yup.string().label('Redemption Frequency').oneOf(frequencies).default('once'),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  scheduleAt: yup.date().nullable().required(),
  scheduleEndAt: yup.date().nullable().required(),
  dailySchedules: dailySchedules(),
  active: yup.boolean().default(true),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const questSchemaPartial = yup.object().shape(shape)
const questSchema = questSchemaPartial.shape(requiredFields(['name'])(shape))
const questFormSchema = questSchema.shape({
  scheduleAt: yup.mixed().datetime(),
  scheduleEndAt: yup.mixed().datetime(),
})

module.exports = { questSchema, questSchemaPartial, questFormSchema }
