const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  reward: yup.mixed(),
  profile: yup.mixed(),
  status: yup.string().oneOf(['requested', 'approved', 'denied']).default('requested'),
  redeemedAt: yup.date(),
  pointsUsed: yup.number().min(0).default(0),
  reason: yup.string().ensure(),
  processedBy: yup.mixed(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const rewardRedemptionSchemaPartial = yup.object().shape(shape)
const rewardRedemptionSchema = rewardRedemptionSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { rewardRedemptionSchema, rewardRedemptionSchemaPartial }
