const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const activeStatuses = ['building', 'driving']
const inactiveStatuses = ['completed', 'cancelled', 'invalid', 'expired']

const shape = labelify({
  resort: yup.mixed(),
  profile: yup.mixed(),
  name: yup.string().ensure(),
  code: yup.string().nullable(),
  uuid: yup.string().ensure(),
  status: yup
    .string()
    .oneOf([...activeStatuses, ...inactiveStatuses])
    .ensure()
    .default('building'),
  distanceMiles: yup.number().min(0),
  started: yup.mixed(),
  ended: yup.mixed(),
  party: yup.array(yup.mixed()),
  invalidReason: yup.string().ensure(),
  invalidatedAt: yup.date(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const carpoolSchemaPartial = yup.object().shape(shape)
const carpoolSchema = carpoolSchemaPartial.shape(requiredFields(['name'])(shape))
const carpoolSchemaGet = carpoolSchema.shape({
  locationHistory: yup
    .array()
    .of(yup.object({ profile: yup.mixed(), location: yup.object().shape({ latitude: yup.number(), longitude: yup.number(), time: yup.date() }) })),
})

module.exports = { carpoolSchema, carpoolSchemaPartial, carpoolSchemaGet }
