const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { types, frequencies } = require('../carpool-rewards')
const mobileUserTypes = require('../mobile-user-types')
const { dailySchedules } = require('./fields/daily-schedules')
const { uploadSchema } = require('./cloudinary-schema')

const shape = labelify({
  name: yup.string().label('Title').default(''),
  type: yup.string().oneOf(types).default('carpool'),
  description: yup.string().default(''),
  pointsCost: yup.number().min(0).default(0),
  maximumRedemptionFrequency: yup.string().label('Redemption Frequency').oneOf(frequencies).default('once'),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  scheduleAt: yup.date().nullable().required(),
  scheduleEndAt: yup.date().nullable().required(),
  dailySchedules: dailySchedules(),
  image: uploadSchema,
  active: yup.boolean().default(true),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const rewardSchemaPartial = yup.object().shape(shape)
const rewardSchema = rewardSchemaPartial.shape(requiredFields(['name'])(shape))
const rewardFormSchema = rewardSchema.shape({
  scheduleAt: yup.mixed().datetime().required(),
  scheduleEndAt: yup.mixed().datetime().required(),
})
module.exports = { rewardSchema, rewardSchemaPartial, rewardFormSchema }
